<!--  -->
<template>
  <div class='xuexi round bj-col-white pad'>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="公需科目(学习)" name="1">
        <gong-xu v-if="activeName == '1'"></gong-xu>
      </el-tab-pane>

      <el-tab-pane label="专业科目(学习)" name="2">
        <xuan-ke v-if="activeName == '2'"></xuan-ke>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import XuanKe from './tabs/xuanke.vue'
import GongXu from './tabs/gongxu.vue'

export default {
  components: {
    XuanKe,
    GongXu
  },
  data() {
    return {
      activeName: '1'
    };
  },
  computed: {},
  watch: {
    
  },
  created() {
  
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    
  },
}
</script>

<style lang='less' scoped>
.xuexi {
  /deep/ .el-tabs__item {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>